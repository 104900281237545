<template>
	<div class="card shadow">
		<div class="card-body">
			<form ref="inspeccionForm" v-on:submit.prevent="validateInspeccion()" class="needs-validation was-validated" novalidate>
				<div class="row">
					<div class="col-md-6 mb-2">
						<h2 class="font-weight-bold">INSPECCIÓN DE INMUEBLE</h2>
					</div>
					<div class="col-md-6 mb-1">
						<div class="d-flex">
							<div class="border rounded px-3 py-2 mr-3">
								{{$filters.date(inspeccion.fechaCreacion)}}
							</div>
							<div class="border rounded p-2 w-50">
								No. {{id}}
							</div>
						</div>
					</div>
				</div>
				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-md-12 mb-2">
								<span>Características del sector:</span>
								<div class="d-flex">
									<textarea class="form-control border" name="caracteristicas" required v-model="inspeccion.caracteristicasSector" :disabled="inspeccion.estado != 0" maxlength="500"></textarea>
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<div class="d-flex align-items-lg-center flex-column flex-lg-row align-items-start">
									<span class="mr-2">Otras observaciones:</span>
									<div class="flex-lg-fill">
										<input type="text" name="otrasObservaciones" class="form-control border" v-model="inspeccion.otrasObservaciones" :disabled="inspeccion.estado != 0" maxlength="500">
									</div>
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<span>Acceso a la propiedad:</span>
								<div class="d-flex">
									<textarea class="form-control border" name="accesoPropiedad" required v-model="inspeccion.accesoPropiedad" :disabled="inspeccion.estado != 0" maxlength="500"></textarea>
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<span>Descripción de la propiedad:</span>
								<div class="d-flex">
									<textarea class="form-control border" name="descripcion" required v-model="inspeccion.descripcion" :disabled="inspeccion.estado != 0" maxlength="500"></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-md-12 mb-2">
								<h4>Características de la propiedad:</h4>
							</div>
							<div class="col-md-12 mb-2">
								<span>Área registrada en el RGP:</span>
								<div class="d-flex">
									<input type="text" name="areaRgpMts" class="form-control border" placeholder="Mts" v-model="inspeccion.areaRgpMts" required :disabled="inspeccion.estado != 0">
									<input type="text" name="areaRgpVr" class="form-control border" placeholder="Vr" v-model="inspeccion.areaRgpVr" required :disabled="inspeccion.estado != 0">
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<span>Área física medida:</span>
								<div class="d-flex">
									<input type="text" name="areaFisicaMts" class="form-control border" required placeholder="Mts" v-model="inspeccion.areaFisicaMts" :disabled="inspeccion.estado != 0">
									<input type="text" name="areaFisicaVr" required class="form-control border" placeholder="Vr" v-model="inspeccion.areaFisicaVr" :disabled="inspeccion.estado != 0">
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<span>Metros cuadrados de construcción:</span>
								<div class="d-flex">
									<input type="text" name="metrosConstruccion" required class="form-control border" placeholder="Mts" v-model="inspeccion.metrosConstruccion" :disabled="inspeccion.estado != 0">
									<input type="text" name="metrosConstruccionVr" required class="form-control border" placeholder="Vr" v-model="inspeccion.metrosConstruccionVr" :disabled="inspeccion.estado != 0">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-md-12 mb-3">
								<h4>Servicios públicos:</h4>
							</div>
							<div class="col-md-4 mb-2">
								<span>Sistema de drenajes</span>
								<div class="row align-items-center">
									<div class="col-md-12 mb-2">
										<div class="custom-control custom-radio">
											<input type="radio" id="siPoseeDrenaje" name="drenajes" class="custom-control-input" v-model="inspeccion.sistemaDrenajes" :disabled="inspeccion.estado != 0" value="1">
											<label class="custom-control-label" for="siPoseeDrenaje">Si posee</label>
										</div>
										<div class="custom-control custom-radio">
											<input type="radio" id="noPoseeDrenaje" name="drenajes" class="custom-control-input" v-model="inspeccion.sistemaDrenajes" :disabled="inspeccion.estado != 0" value="0">
											<label class="custom-control-label" for="noPoseeDrenaje">No posee</label>
										</div>
									</div>
									<div class="col-md-12 mb-2">
										<input type="text" name="obsvDrenajes" class="form-control border" placeholder="Observaciones" v-model="inspeccion.obsvDrenajes" :disabled="inspeccion.estado != 0" maxlength="250">
									</div>
								</div>
							</div>
							<div class="col-md-4 mb-2">
								<span>Agua entubada</span>
								<div class="row align-items-center">
									<div class="col-md-12 mb-2">
										<div class="custom-control custom-radio">
											<input type="radio" id="municipal" name="customRadio" class="custom-control-input" v-model="inspeccion.aguaEntubada" value="1" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="municipal">Municipal</label>
										</div>
										<div class="custom-control custom-radio">
											<input type="radio" id="comunal" name="customRadio" class="custom-control-input" v-model="inspeccion.aguaEntubada" value="2" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="comunal">Comunal</label>
										</div>
										<div class="custom-control custom-radio">
											<input type="radio" id="privada" name="customRadio" class="custom-control-input" v-model="inspeccion.aguaEntubada" value="3" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="privada">Privada</label>
										</div>
										<div class="custom-control custom-radio">
											<input type="radio" id="propio" name="customRadio" class="custom-control-input" v-model="inspeccion.aguaEntubada" value="4" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="propio">Propio</label>
										</div>
										<div class="custom-control custom-radio">
											<input type="radio" id="noPosee" name="customRadio" class="custom-control-input" v-model="inspeccion.aguaEntubada" value="5" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="noPosee">No posee</label>
										</div>
									</div>
									<div class="col-md-12 mb-2">
										<input type="text" name="obsAgua" class="form-control border" placeholder="Observaciones" v-model="inspeccion.obsvAgua" :disabled="inspeccion.estado != 0" maxlength="250">
									</div>
								</div>
							</div>
							<div class="col-md-4 mb-2">
								<span>Otros servicios</span>
								<div class="row align-items-center">
									<div class="col-md-12 mb-2">
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="servicioInternet" v-model="inspeccion.servicioInternet" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="servicioInternet">Servicio de internet</label>
										</div>
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="servicioCable" v-model="inspeccion.servicioCable" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="servicioCable">Servicio de cable</label>
										</div>
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="extraccionBasura" v-model="inspeccion.extraccionBasura" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="extraccionBasura">Extracción de basura</label>
										</div>
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="garita" v-model="inspeccion.garita" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="garita">Garita de seguridad</label>
										</div>
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="camaras" v-model="inspeccion.camaras" :disabled="inspeccion.estado != 0">
											<label class="custom-control-label" for="camaras">Cámaras de seguridad</label>
										</div>
									</div>
									<div class="col-md-12 mb-2">
										<input type="text" name="obsvOtrosServicios" class="form-control border" placeholder="Observaciones" v-model="inspeccion.obsvOtrosServicios" :disabled="inspeccion.estado != 0" maxlength="250">
									</div>
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<div class="d-flex align-items-lg-center flex-column flex-lg-row align-items-start">
									<span class="mr-2">Costo del mantenimiento:</span>
									<div class="flex-lg-fill">
										<input type="number" name="mantenimiento" class="form-control border" v-model="inspeccion.mantenimiento" required :disabled="inspeccion.estado != 0">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-md-12 mb-2">
								<h4>Características de la construcción:</h4>
							</div>
							<div class="col-md-12 mb-2">
								<div class="d-flex align-items-lg-center flex-column flex-lg-row align-items-start">
									<span class="mr-2">Estado de construcción:</span>
									<div class="flex-lg-fill">
										<textarea class="form-control border" name="estadoConstruccion" v-model="inspeccion.estadoConstruccion" required :disabled="inspeccion.estado != 0" maxlength="500"></textarea>
									</div>
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<div class="d-flex align-items-lg-center flex-column flex-lg-row align-items-start">
									<span class="mr-2">Estructura:</span>
									<div class="flex-lg-fill">
										<textarea class="form-control border" name="estructura" v-model="inspeccion.estructura" required :disabled="inspeccion.estado != 0" maxlength="500"></textarea>
									</div>
								</div>
							</div>
							<div class="col-md-12 mb-2">
								<div class="d-flex align-items-lg-center flex-column flex-lg-row align-items-start">
									<span class="mr-2">Observaciones generales:</span>
									<div class="flex-lg-fill">
										<textarea class="form-control border" name="obsGenerales" v-model="inspeccion.obsvGenerales" required :disabled="inspeccion.estado != 0" maxlength="500"></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-md-12">
								<h4>Medidas:</h4>
								<div class="row" v-for="(input, index) in medidas" :key="`medidasInput-${index}`">
									<div class="col-md-5 mb-2">
										<input type="text" :name="'nombreNivel'+index" class="form-control border" placeholder="Nombre" v-model="input.descripcionMedida" :disabled="inspeccion.estado != 0">
									</div>
									<div class="col-md-2 mb-2">
										<input type="text" :name="'mtsAncho'+index" class="form-control border" placeholder="Mts" v-model="input.mtsAncho" :disabled="inspeccion.estado != 0">
									</div>
									<div class="col-md-2 mb-2">
										<input type="text" :name="'mtsLargo'+index" class="form-control border" placeholder="Mts" v-model="input.mtsLargo" :disabled="inspeccion.estado != 0">
									</div>
									<div class="col-md-2 mb-2">
										<input type="text" :name="'total'+index" class="form-control border" placeholder="total" v-model="input.total" :disabled="inspeccion.estado != 0">
									</div>
									<div class="col-md-1 mb-2">
										<button type="button" class="btn btn-primary rounded-pill font-weight-bold mr-3"  v-on:click="removeInput(index)" v-if="inspeccion.estado == 0">
											<span class="d-none d-md-block">
												X
											</span>
											<span class="d-block d-md-none">
												Quitar campo
											</span>
										</button>
									</div>
									<div class="col-md-12">
										<hr>
									</div>
								</div>
							</div>
							<div class="col-md-6 mb-2" v-if="inspeccion.estado == 0">
								<input type="number" v-model="agregarMedida" class="form-control" :disabled="inspeccion.estado != 0">
							</div>
							<div class="col-md-6 mb-2" v-if="inspeccion.estado == 0">
								<button type="button" class="btn btn-primary rounded-pill font-weight-bold mr-3" v-on:click="addInput()">Añadir campos</button>
							</div>
							<div class="col-md-12 mb-2">
								<h4>Medidas del terreno:</h4>
							</div>
							<div class="col-md-6 mb-2">
								<label for="medidaFrente">Frente</label>
								<input type="number" name="medidaFrente" id="medidaFrente" class="form-control border" required placeholder="Mts" :disabled="inspeccion.estado != 0" v-model="inspeccion.medidaFrente">
							</div>
							<div class="col-md-6 mb-2">
								<label for="medidaTrasera">Trasera</label>
								<input type="number" name="medidaTrasera" id="medidaTrasera" class="form-control border" required placeholder="Mts" :disabled="inspeccion.estado != 0" v-model="inspeccion.medidaTrasera">
							</div>
							<div class="col-md-6 mb-2">
								<label for="medidaLateralIzq">Lateral izquierdo</label>
								<input type="number" name="medidaLateralIzq" id="medidaLateralIzq" class="form-control border" required placeholder="Mts" :disabled="inspeccion.estado != 0" v-model="inspeccion.medidaLateralIzq">
							</div>
							<div class="col-md-6 mb-2">
								<label for="medidaLateralDer">Lateral derecho</label>
								<input type="number" name="medidaLateralDer" id="medidaLateralDer" class="form-control border" required placeholder="Mts" :disabled="inspeccion.estado != 0" v-model="inspeccion.medidaLateralDer">
							</div>
						</div>
					</div>
				</div>
				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-md-12 mb-2">
								<span>Valor de registro:</span>
								<input type="number" name="valorRegistro" class="form-control border" v-model="inspeccion.valorRegistro" :disabled="inspeccion.estado != 0">
							</div>
							<div class="col-md-12 mb-2">
								<span>Potencial de mercado:</span>
								<textarea class="form-control border" name="potencialMercado" v-model="inspeccion.potencialMercado" :disabled="inspeccion.estado != 0" maxlength="150"></textarea>
							</div>
							<div class="col-md-6 mb-2">
								<span>Valor comercial:</span>
								<input type="number" name="valorComercial" class="form-control border" v-model="inspeccion.valorComercial" :disabled="inspeccion.estado != 0">
							</div>
							<div class="col-md-6 mb-2">
								<span>Valor bancario:</span>
								<input type="number" name="valorBancario" class="form-control border" v-model="inspeccion.valorBancario" :disabled="inspeccion.estado != 0">
							</div>
							<div class="col-md-6 mb-2">
								<span>Rentabilidad en renta:</span>
								<input type="number" name="rentabilidadRenta" class="form-control border" v-model="inspeccion.rentabilidadRenta" :disabled="inspeccion.estado != 0">
							</div>
							<div class="col-md-6 mb-2">
								<span>Rentabilidad en venta:</span>
								<input type="number" name="retabilidadVenta" class="form-control border" v-model="inspeccion.retabilidadVenta" :disabled="inspeccion.estado != 0">
							</div>
						</div>
					</div>
				</div>
				<div class="card cap-bg mb-3 border">
					<div class="card-body">
						<div class="row">
							<div class="col-md-12 mb-2">
								<div class="row" v-for="(section, indexSection) in fotosSection" :key="`fotosSection-${indexSection}`">
									<div class="col-md-10 mb-2">
										<input type="text" :name="'nombreNivel'+indexSection" class="form-control" placeholder="Nombre sección" :disabled="inspeccion.estado != 0" v-model="section.descripcion">
									</div>
									<div class="col-md-2 mb-2">
										<button type="button" v-if="inspeccion.estado == 0" class="btn btn-primary rounded-pill font-weight-bold mr-3"  v-on:click="removeSection(indexSection)">
											<span class="d-none d-md-block">
												X
											</span>
											<span class="d-block d-md-none">
												Quitar campo
											</span>
										</button>
									</div>
									<div class="col-md-5 mb-3" v-for="(foto, indexFoto) in section.fotos" :key="indexFoto">
										<img v-lazy="$filters.storageMin(foto.url)" class="img-fluid rounded shadow" :alt="'Inmueble'+(indexFoto+1)" v-if="foto.url">
										<input type="text" :name="'descripcionFoto'+indexSection+'_'+indexFoto" class="form-control" placeholder="Descripción de imagen" :disabled="inspeccion.estado != 0" v-model="foto.descripcion">
									</div> 
									<div class="col-md-5" v-if="!disabledDF">
										<Upload class="mb-3 text-center w-100 cursor-pointer border-dashed rounded-sm overflow-hidden bg-white p-5" :name="'fotos'+indexSection" v-on:fileChange="uploadFileFotos($event, indexSection)" :maxFiles="1" v-if="inspeccion.estado == 0">
											<img src="@/assets/image/cruz.webp" class="img-fluid mx-auto" alt="Agregar foto" title="Agregar foto">
										</Upload>
									</div>
									<div class="col-md-12">
										<hr>
									</div>
								</div>
							</div>
							<div class="col-md-6 mb-2" v-if="inspeccion.estado == 0">
								<input type="number" v-model="agregarSection" class="form-control" :disabled="inspeccion.estado != 0">
							</div>
							<div class="col-md-6 mb-2" v-if="inspeccion.estado == 0">
								<button type="button" class="btn btn-primary rounded-pill font-weight-bold mr-3" v-on:click="addSection()">Añadir sección(es)</button>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!disabledDF && user.prf==3">
					<div class="d-flex justify-content-end">
						<button type="submit" class="btn btn-primary rounded-pill font-weight-bold mr-3" :disabled="loading && validateForm">
							<BtnLoading v-if="loadingSave" />
							<span v-else>
								<span class="material-icons align-middle">save</span> Guardar
							</span>
						</button>
						<button type="button" v-if="inspeccion.estado == 0" v-on:click="validateInspeccion(1)" class="btn btn-success rounded-pill font-weight-bold mr-3" :disabled="loading">
							<BtnLoading v-if="loadingAutorizar" />
							<span v-else>
								<span class="material-icons align-bottom">check</span>
								Autorizar
							</span>
						</button>
						<button type="button" v-if="inspeccion.estado == 0" v-on:click="validateInspeccion(2)" class="btn btn-danger rounded-pill font-weight-bold mr-3" :disabled="loading">
							<BtnLoading v-if="loadingRechazar" />
							<span v-else>
								<span class="material-icons align-bottom">close</span>
								Rechazar
							</span>
						</button>
					</div>
				</div>			
			</form>
		</div>
	</div>	
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		props:{
			disabled: Boolean
		},

		components:{
			BtnLoading,
			Upload

		},

		data(){
			return{
				medidas: [],
				fotosSection: [],
				agregarMedida: 1,
				agregarSection: 1,
				validateForm: false,
				loadingSave: false,
				loadingAutorizar: false,
				loadingRechazar: false,
				fotos: [],
				inspeccion:{
					idPrenda: '', 
					caracteristicasSector: '',
					otrasObservaciones: '',
					accesoPropiedad: '',
					descripcion: '',
					areaRgpMts: '',
					areaRgpVr: '',
					areaFisicaMts: '',
					areaFisicaVr: '',
					metrosConstruccion: '',
					metrosConstruccionVr: '',
					aguaEntubada: '',
					obsvAgua: '',
					sistemaDrenajes: '',
					obsvDrenajes: '',
					servicioInternet: '',
					servicioCable: '',
					extraccionBasura: '',
					garita: '',
					camaras: '',
					obsvOtrosServicios: '',
					mantenimiento: '',
					estadoConstruccion: '',
					estructura: '',
					obsvGenerales: '',
					valorRegistro: '',
					potencialMercado: '',
					valorComercial: '',
					valorBancario: '',
					rentabilidadRenta: '',
					rentabilidadVenta: '',
					medidaFrente: '',
					medidaTrasera: '',
					medidaLateralIzq: '',
					medidaLateralDer: '',
					fotos: [],
					estado: 0
				},

				section:{
					descripcion: ''
				},

				input:{
					descripcionMedida: '',
					mtsAncho: '',
					mtsLargo: '',
					total: ''
				}
			}
		},

		mounted(){
			this.getInfo()
			this.getInfoMedidas()
			this.getInfoFotos()
		},

		methods: {
			groupBy(xs, key){
				var data = []
				for (var i = 0; i < xs.length; i++) {
					var item = xs[i]
					var index = item[key] || 0
					if(data[index] !== undefined){
						data[index].fotos.push(item || {})
					}else{
						data.push({
							descripcion: item.grupo || '',
							fotos: [item || {}]
						})	
					}
				}

				return data
			},

			validateInspeccion(index = 0){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas guardar esta información?`,
					buttons: {
						si: function () {
							mod.sendInspeccion(index)
				    	},
				    	no: function () {

				    	},
				    }
				})
			},
			addInput(){
				if (this.agregarMedida <= 0) {
					return
				}

				for (var i = 0; i < this.agregarMedida; i++) {
					this.medidas.push({ descripcionMedida: "", mtsAncho: "", mtsLargo: "", total: "" })
				}
				this.agregarMedida = 1
			},
			removeInput(index){
				this.medidas.splice(index, 1);
			},

			addSection(){
				if (this.agregarSection <= 0) {
					return
				}

				for (var i = 0; i < this.agregarSection; i++) {
					this.fotosSection.push({ descripcion: "", fotos: [] })
				}
				this.agregarSection = 1
			},

			removeSection(index){
				this.fotosSection.splice(index, 1);
			},

			getInfo(){
				if (!this.id) {return}
				this.$store.dispatch('getInspeccionInmueble', {
					codigoInspeccion: this.id
				}).then(response => {
					this.inspeccion = response.data || {}
					if(!this.inspeccion.estado){
						this.inspeccion.estado = 0
					}
				}).catch()
			},
			getInfoMedidas(){
				if (!this.id) {return}
				this.$store.dispatch('getInspeccionInmuebleMedidas', {
					codigoInspeccion: this.id
				}).then(response => {
					this.medidas = response.data || []
				}).catch()
			},
			getInfoFotos(){
				if (!this.id) {return}
				this.$store.dispatch('getInmueblesFotos', {
					codigoInspeccion: this.id
				}).then(response => {
					this.fotosSection = this.groupBy(response.data || [], 'tipo')
					return response
				}).catch(error =>{
					return error
				})
			},
			sendInspeccion(index = 0){
				if (this.disabledDF && this.user.prf != 3) {
					return
				}
				this.checkValidity()
				if (!this.validateForm) {
					window.toastr.error("Existen campos no válidos.", "Error")
					return
				}
				this.loadingSave = true
				this.inspeccion.fotos = this.fotosSection
				this.inspeccion.medidas = this.medidas
				this.inspeccion.codigoInspeccion = this.id
				var estado = this.inspeccion.estado
				this.inspeccion.estado = index
				this.$store.dispatch('addInspeccionHipoteca', this.inspeccion).then(response => {
					window.toastr.success("La gestión ha sido realizada con éxito.", "Éxito")
					this.inspeccion.fotos = []
					this.inspeccion.medidas = []

					return response
				}).catch(error =>{
					window.toastr.error('Error al guardar registro, intenta de nuevo.', 'Error')
					this.inspeccion.estado = estado
					return error
				}).then(() => {
					this.loadingSave = false
				})
			},

			checkValidity(){
				if (!this.$refs.inspeccionForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.inspeccionForm.checkValidity()
				}
			},

			validateUpdate(estado){
				var mod = this
				$.confirm({
					theme: 'modern',
					title: '¡Confirmación!',
					columnClass: 'col-lg-6',
					content: `¿Estás seguro que deseas ${estado == 1 ? 'autorizar' : 'rechazar'} este vehículo?`,
					buttons: {
						si: function () {
							mod.vehiculoUpdateStatus(estado)
				    	},
				    	no: function () {

				    	},
				    }
				})
			},

			vehiculoUpdateStatus(estado){
				if (estado==1) {
					this.loadingAutorizar = true
				}
				if (estado==4) {
					this.loadingRechazar = true
				}
				this.$store.dispatch('vehiculoUpdateStatus', {
					idVehiculo: this.vehiculo.idVehiculo,
					estatus: estado
				}).then(response => {
					window.toastr.success("Cambio realizado con éxito.", "Éxito")
					if (estado==1) {
						this.getInteres()
					}
					this.$router.push({name: 'admin.vehiculos.pendientes'})
					return response
				}).catch(error =>{
					window.toastr.error('No se pudo completar el cambio, intenta de nuevo.', 'Error')
					return error
				}).then(() => {
					if (estado==1) {
						this.loadingAutorizar = false
					}
					if (estado==4) {
						this.loadingRechazar = false
					}
				})
			},

			uploadFileFotos(file, index){
				this.loadingFileFotos = true
				var formData = new FormData()
				var nameFile = this.uniqueString()+'-'+file.name
				formData.append('files', file)
		        formData.append('nameFile', nameFile)

				this.$store.dispatch('inmueblesUploadInspeccion', formData).then(response => {
					this.fotosSection[index].fotos.push({descripcion:'', url:'https://clubcashin.com/storage/inmuebles/inspecciones/'+nameFile})
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFileFotos = false
				})
			},
			uniqueString() {
				var text     = ""
				var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
				for( var i=0; i < 8; i++ ) {
					text += possible.charAt(Math.floor(Math.random() * (possible.length || 0)))
				}
			    return text;
			},
		},

		computed: {
			prenda(){
				return this.datos || {}
			},
			disabledDF(){
				return this.disabled || false
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
			loading(){
				return this.$store.getters.loading
			},

			id(){
				return this.$route.params.id || -1
			},
		}
	}
</script>